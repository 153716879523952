<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Ledger')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Type -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <b-form-radio-group
                    v-model="ledgerData.type"
                    :options="typeOptions"
                    class="demo-inline-spacing mb-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <!-- Field: Ledger Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="ledgerData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('audit', 'all')"
              cols="12"
              md="4"
            >

              <validation-provider
                #default="validationContext"
                name="branch"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="ledgerData.branch_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="branch"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              type="submit"
              pill
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              pill
              @click="cancel"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import ledgerStoreModule from '../ledgerStoreModule'
import storeModule from '../../../common/storeModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
      typeOptions: [
        { text: this.$t('Cash In'), value: 'cash_in' },
        { text: this.$t('Cash Out'), value: 'cash_out' },
      ],
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'ledger-list' })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'ledger'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, ledgerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    const branchOptions = ref(JSON.parse(JSON.stringify([])))
    store
      .dispatch('common/fetchBranches', { id_name: 1 })
      .then(response => {
        const { branches } = response.data.data
        for (let i = 0; i < branches.length; i += 1) {
          branchOptions.value.push({ label: branches[i].name, value: branches[i].id.toString() })
        }
      })
      .catch(error => {
        console.log(error)
      })
    const blankData = {
      name: '',
      type: '',
      branch_id: null,
    }
    const router = context.root.$router
    const ledgerData = ref(JSON.parse(JSON.stringify(blankData)))
    const objectId = router.currentRoute.params.id
    store.dispatch('ledger/fetchOne', { id: objectId })
      .then(response => {
        const responseLedgerData = {
          id: response.data.data.id,
          name: response.data.data.name,
          type: response.data.data.type,
          branch_id: response.data.data.branch_id.toString(),
        }
        ledgerData.value = responseLedgerData
      })
      .catch(error => {
        if (error.response.status === 404) {
          ledgerData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(ledgerData.value))
      store.dispatch('ledger/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'ledger-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      ledgerData,
      onSubmit,

      refFormObserver,
      getValidationState,
      branchOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
